<!-- 登录页面 -->
<template>
	<div>
		<div class="container">
			<!-- 背景图 -->
			<div class="home">
				<canvas id="canv" class="htmls bodys" style="position: absolute; width: auto; height: auto; left: 0;"></canvas>
			</div>
			<div class="row pt-5">
				<div class="col-12 col-sm-9 col-md-7 col-lg-5 m-auto pt-5" >
					<div class="card mt-5" style="background-color: rgba(255,255,255,0.4)">
						<div class="card-header">
							<h4 class="text-center mb-0" style="font-weight:800;	background-color: unset; color: white;">{{ $conf.logo }}</h4>
						</div>
						<div class="card-body">
							<el-form ref="ruleForm" :rules="rules" :model="form">
								<el-form-item prop="username"><el-input v-model="form.username" size="medium" placeholder="请输入用户名"></el-input></el-form-item>
								<el-form-item prop="password"><el-input v-model="form.password" size="medium" type="password" placeholder="请输入密码"></el-input></el-form-item>
								<el-form-item>
									<el-button type="primary" size="medium" class="w-100" @click="submit" :loading="loading">{{ loading ? '登录中...' : '立即登录' }}</el-button>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" size="medium" class="w-100" @click="submit(1)" :loading="loading">{{ loading ? '登录中...' : '测试通道' }}</el-button>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
let ms = [];
import { stars_nest } from '@/views/login/index.js'; // 背景特效
import { mapGetters } from 'vuex'; // 引入vuex 拿到 全局 公共数据
export default {
	name: 'Home',
	data() {
		return {
			loading: false,
			form: {
				username: '',
				password: ''
			},
			rules: {
				username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
			}
		};
	},
	// 生命周期
	computed: {
		...mapGetters(['adminIndex'])
	},
	mounted() {
		//****在这里绑定监听器
		stars_nest();
	},
	methods: {
		submit(num) {
			if(num == 1){
				this.form.username = 'qfy0752'
				this.form.password = 'qfy0752'
			}
			
			this.$refs.ruleForm.validate(e => {
				if (!e) return;
				// 提交表单
				this.loading = true;
				this.axios
					.post('/admin/login', this.form)
					.then(res => {
						// this.$message('密码错误');
						console.log(res.config)
						// 存储到vuex
						let data = res.data.data;
						// 存储到本地存储
						this.$store.commit('login', data);
						// 存储权限规则
						if (data.role && data.role.rules) {
							window.sessionStorage.setItem('rules', JSON.stringify(data.role.rules));
						}
						// 生成后台菜单
						this.$store.commit('createNavBar', data.tree);
						// 成功提示
						this.$message('登录成功');
						this.loading = false;
						// 跳转后台首页
						this.$router.push({ name: this.adminIndex });
					})
					.catch(err => {
						this.loading = false;
					});
			});
		}
	}
};
</script>

<style>

	.htmls {
		height: 100%;
		background-image: -webkit-radial-gradient(ellipse farthest-corner at center top, #000d4d 0%, #000105 100%);
		background-image: radial-gradient(ellipse farthest-corner at center top, #000d4d 0%, #000105 100%);
		cursor: arrow;
	}
	
	.bodys {
		width: 100%;
		margin: 0;
		overflow: hidden;
	}
</style>
